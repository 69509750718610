<user-avatar *ngIf="notification.fromUser; else realizedByUser"
	[user]="notification.fromUser"
	[hasFullName]="true"
/>

<ng-template #realizedByUser>
	<user-avatar *ngIf="notification.realizedByUser && !fromUserPrevails && !isDebtorPortalUser; else systemUser"
		[user]="notification.realizedByUser.notdefined ? notification.user : notification.realizedByUser"
		[hasFullName]="true"
	/>
</ng-template>		

<ng-template #systemUser>
	<user-avatar *ngIf="isSystemUser; else debtorPortalUser" [isSystem]="true"/>
</ng-template>

<ng-template #debtorPortalUser>
	<user-avatar [isDebtorPortal]="true"/>
</ng-template>