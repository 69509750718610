
import { Component, Input } from '@angular/core';
import { INotificationItem } from 'apps/middle/src/app/shared-module/models';
import { NgIf } from '@angular/common';

import { NotificationCategory } from '../../enums';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
	selector: 'notification-item-avatar',
	templateUrl: './notification-item-avatar.component.html',
	standalone: true,
	imports: [NgIf, UserAvatarComponent]
})
export class NotificationItemAvatarComponent {
	@Input() notification: INotificationItem;

	notificationsCategories = NotificationCategory;

	get isSystemUser(): boolean {
		return this.notification.fromUser === null && !this.isDebtorPortalUser;
	}

	get fromUserPrevails(): boolean {
		return [
			NotificationCategory.DunningActionPostalMailError,
		].includes(this.notification.category);
	}

	get isDebtorPortalUser(): boolean {
		return this.notification.fromUser === null &&
					(this.notification.category === this.notificationsCategories.DebtorPortalPaymentLinkClicked ||
					 this.notification.category === this.notificationsCategories.DebtorPortalCommentAdded);
	}
}
